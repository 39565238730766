<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Warehouses/List</h4>

          <div>
            <router-link :to="can('warehouse-create') ? {name:'appWarehouseCreate'} : '#'">
              <span :title="can('warehouse-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>

        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect :allow-empty="false" v-model="selectedPagination" class="" :options="pagination"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect :allow-empty="false" v-model="selectedState" class="" :options="state"
                                :close-on-select="true" label="name"
                                track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect :allow-empty="false" v-model="selectedStatus" class="" :options="status"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getWarehousesParam.name" class="form-control search-product-input-element" type="text"
                       placeholder="Search a warehouse by name">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyWarehouseFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">NAME</th>
                    <th class="position-relative" :style="{width:'30%'}">ADDRESS</th>
                    <th class="position-relative" :style="{width:'20%'}">CONTACT NUMBER</th>
                    <th class="position-relative" :style="{width:'20%'}">MANAGER NAME</th>
                    <th class="position-relative" :style="{width:'20%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'10%'}">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(warehouse) in warehouses" :key="warehouse.id">
                    <td class="text-capitalize">{{ warehouse.name }}</td>
                    <td v-if="warehouse.address">{{ warehouse.address.street }},
                      {{ warehouse.address.suburb }}
                      <span class="badge badge-sm badge-light-success text-uppercase">{{
                          warehouse.address.state
                        }}</span>
                      {{ warehouse.address.post_code }},
                      {{ warehouse.address.country }}
                    </td>
                    <td>{{ warehouse.contact_number }}</td>
                    <td class="text-capitalize">
                      {{ `${warehouse?.managedBy?.first_name?? ''} ${warehouse?.managedBy?.last_name??''}` }}
                    </td>
                    <td><span
                        :class="warehouse.status === 'Active'? 'badge badge-sm badge-light-success': 'badge badge-sm badge-light-danger' ">{{
                        warehouse.status
                      }}</span></td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getSingleWarehouse(warehouse.id)" data-toggle="modal"
                               data-target="#warehouseDetails">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <router-link class="text-decoration-none"
                                     :to="can('warehouse-update') ? {name:'appWarehouseView',params:{id:warehouse.id}} : '#'"
                                     :title="can('warehouse-update') ? 'Update' : 'Update forbidden'"
                                     :style="{height:'18px'}">
                            <i class='bx bx-edit'></i>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    :disabled="!can('warehouse-delete')"
                                    :title="can('warehouse-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-toggle="modal" data-target="#warehouseDeleteAlertModal"
                                    @click="this.modelIdForDelete = warehouse.id">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyWarehouseFilter"/>
              </div>
            </div>
          </div>
        </div>
        <WarehouseDeleteAlertModal :model-id="modelIdForDelete" model-name="warehouse"
                                   modal-name="warehouseDeleteAlertModal"
                                   @confirmModelDeletion="onConfirmModelDeletion"/>
        <WarehouseModal :current-warehouse="this.warehouse"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import WarehouseDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import WarehouseModal from "@/views/backEnd/Warehouse/includes/WarehouseModal";
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "ListWarehouses",
  components: {
    AppLayout,
    WarehouseDeleteAlertModal,
    WarehouseModal,
    ListPagination,
  },
  mixins: [ShowToastMessage, Authorization, Loader],
  data() {
    return {
      modelIdForDelete:'',
      warehouse: {},
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      status: [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],
      getWarehousesParam: {
        name: '',
        status: '',
        where_has_address_state: '',
        with_relation: ['address', 'managedBy'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state'],
      },
    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getWarehousesParam.pagination = pagination.value;
    },
    selectedState: function (state) {
      this.getWarehousesParam.where_has_address_state = state.value;
    },
    selectedStatus: function (status) {
      this.getWarehousesParam.status = status.value;
    },
  },
  computed: {
    ...mapGetters({
      warehouses: 'appWarehouses/warehouses',
      paginateLinks: 'appWarehouses/paginateLinks',
    }),
    pagination() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
    state() {
      return [{value: '', name: 'Any'}, ...this.$store.getters['appSettings/settingDefaultState'].value];
    },
  },
  methods: {
    ...mapActions({
      getWarehouses: 'appWarehouses/getWarehouses',
      getWarehouse: 'appWarehouses/getWarehouse',
      getSettings: 'appSettings/getSettings',

      deleteWarehouseOnList: 'appWarehouses/deleteWarehouseOnList'

    }),
    async deleteSingleWarehouseOnLIst(id) {
      this.deleteWarehouseOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Warehouse deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {

      await this.deleteSingleWarehouseOnLIst(confirmModelDeletion.modelId);
    },
    async getWarehousesList(warehousesParams) {
      await this.getWarehouses(warehousesParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          });
    },

    async applyWarehouseFilter(pageNumber) {
      await this.loader(true);
      this.getWarehousesParam.page = pageNumber;
      await this.getWarehousesList(this.getWarehousesParam);
      await this.loader(false);
    },

    async getSingleWarehouse(id) {
      const params = {
        id: id,
        params: this.getWarehousesParam,
      };
      await this.getWarehouse(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.warehouse = this.$store.getters['appWarehouses/warehouse'];
            }

            if (response.message) {
              this.showToastMessage(response);
            }

          });
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.getWarehousesList(this.getWarehousesParam);
    await this.loader(false);
  },

}
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }
}
</style>
