<template>
  <div class="modal fade " id="warehouseDetails" tabindex="-1" role="dialog" aria-labelledby="warehouseDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Warehouse Details</h5>
          <button @click="resetWarehouse" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th class="col-3">Name</th>
              <td class="col-9 text-capitalize">{{ name }}</td>
            </tr>

            <tr>
              <th class="col-3">Address</th>
              <td class="col-9" v-if="address.street">{{ address.street }},
                {{ address.suburb }}
                {{ address.state }}
                {{ address.postCode }},
                {{ address.country }}
              </td>
            </tr>
            <tr>
              <th class="col-3">Managed By</th>
              <td class="col-9 text-capitalize">
                {{ user.firstName + '' + user.lastName }}
              </td>
            </tr>
            <tr>
              <th class="col-3">Status</th>
              <td class="col-9"><span></span>
                {{ status }}
              </td>
            </tr>

            <tr>
              <th class="col-3">Phone</th>
              <td class="col-9">{{ contactNumber }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button @click="resetWarehouse" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'WarehouseModal',
  props: ['currentWarehouse'],
  data() {
    return {
      id: '',
      name: '',
      gpsLocation: '',
      status: '',
      contactNumber: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        avatar: '',
      },
      address: {
        id: '',
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: '',
      }
    }
  },
  watch: {
    currentWarehouse() {
      this.id = this.currentWarehouse && this.currentWarehouse.id ? this.currentWarehouse.id : '';
      this.name = this.currentWarehouse && this.currentWarehouse.name ? this.currentWarehouse.name : '';
      this.status = this.currentWarehouse && this.currentWarehouse.status ? this.currentWarehouse.status : '';
      this.gpsLocation = this.currentWarehouse && this.currentWarehouse.gps_location ? this.currentWarehouse.gps_location : '';
      this.contactNumber = this.currentWarehouse && this.currentWarehouse.contact_number ? this.currentWarehouse.contact_number : '';
      this.user = {
        id: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.id ? this.currentWarehouse.managedBy.id : '',
        firstName: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.first_name ? this.currentWarehouse.managedBy.first_name : '',
        lastName: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.last_name ? this.currentWarehouse.managedBy.last_name : '',
        email: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.email ? this.currentWarehouse.managedBy.email : '',
        phoneNumber: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.phone_number ? this.currentWarehouse.managedBy.phone_number : '',
        dateOfBirth: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.date_of_birth ? this.currentWarehouse.managedBy.date_of_birth : '',
        avatar: this.currentWarehouse && this.currentWarehouse.managedBy && this.currentWarehouse.managedBy.avatar ? this.currentWarehouse.managedBy.avatar : '',
      };
      this.address = {
        id: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.id ? this.currentWarehouse.address.id : '',
        street: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.street ? this.currentWarehouse.address.street : '',
        suburb: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.suburb ? this.currentWarehouse.address.suburb : '',
        state: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.state ? this.currentWarehouse.address.state : '',
        postCode: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.post_code ? this.currentWarehouse.address.post_code : '',
        country: this.currentWarehouse && this.currentWarehouse.address && this.currentWarehouse.address.country ? this.currentWarehouse.address.country : '',
      }
    },
  },
  methods: {
    resetWarehouse() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
}
</script>

<style scoped>

</style>